<template>
  <div>
    <div v-for="(item,index) in data" :key="index" class="card-item">
      <div class="row ">
        <div class="left size28">
          <span>诊断结果：</span>
          <span v-for="res in item.diagnosisList" :key="res.zdbm" class="theme-color">{{ res.zdmc }}</span>
        </div>
      </div>
      <div class="text-row">
        建议用药：<span v-for="drug in item.preDetails" :key="drug.id">{{ drug.xmbzmc }}</span>
      </div>
      <div class="fontSize">
        就诊人：{{ item.patientName }}
      </div>
      <div class="row mt24">
        <div class="left desc">
          <default-image :url="item.txljdz" class="photo" width="24px" height="24px" :is-avatar="true" round />
          <span class="docInfo">{{ item.kfysxm }} &nbsp; {{ item.kfsj }}</span>
        </div>
      </div>
      <div class="border" />
      <div class="row mt24">
        <div class="left desc" />
        <div v-if="type=='prescription'" class="right">
          <div v-if="item.orderzt==='1' && item.expressOrder==='0'" class="status blue">
            快递费待支付
          </div>
          <div v-show="item.cfzt=='3'" class="status grey">
            {{ item.cfztmc }}
          </div>
          <!-- 显示待审核和已审核的处方 -->
          <div v-show="item.cfzt=='1'||item.cfzt=='2'" class="status blue" @click="routeTo(item)">
            {{ item.cfztmc }}
          </div>
        </div>
        <div v-else class="right">
          <div v-if="item.orderzt==='1' && item.expressOrder==='0'" class="status blue">
            快递费待支付
          </div>
          <div v-if="item.orderzt" class="status blue" @click="routeTo(item)">
            {{
              item.orderzt == '0' ? '待支付' : item.orderzt == '1' ? '已支付' : item.orderzt == '2' ? '已退号' : item.orderzt == '3' ? '支付中' : item.orderzt == '4' ? '支付失败' : ''
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {setItem} from '@/utils/cache'

export default {
  name: 'PrescriptionItem',
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    type: {
      type: String,
      default: 'prescription'
    },
    // true:我的处方, false:我的购药
    isPrescription: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    routeTo(item) {
      if (item.cfzt == "2") { //已审核
        if (item.orderzt == '0' || item.orderzt == '3') {
          //待支付||支付中
          this.$router.push(`/pay?id=${item.orderid}`)
        } else if (item.orderzt == '1' || item.orderzt == '2') {
          //支付完成
          if (this.isPrescription) {
            setItem('prescriptionItem', item)
            this.$router.push('/paid')
          } else {
            this.$router.push({
              path: '/purchased',
              query: {
                orderId: item.orderid
              }
            })
          }
          // this.$router.push(`/paid?zjhm=${this.zjh}&orderid=${item.orderid}`)
          // this.$emit('click-btn')
        }
      } else if (item.cfzt == '1') { //待审核
        this.$router.push("/inReview?status=" + item.cfzt + "&id=" + item.id + "&zjhm=" + item.zjhm)
      }
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";

.card-item {
  width: 100%;
  background: #FFFFFF;
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 24px 32px;
  box-sizing: border-box;

  .size28 {
    font-size: 28px;
  }

  .row {
    display: flex;
    font-weight: 400;

    .left {
      display: flex;
    }

    .right {
      flex: 1;
      justify-content: flex-end;
    }

    .large-font {
      font-weight: 400;
      font-size: 28px;
      color: @dark-font-color;
    }

    .theme-color {
      color: @theme-color
    }

    .photo {
      margin-right: 24px;
    }

    .docInfo {
      line-height: 48px;
      font-size: 24px;
    }
  }

  .desc {
    font-size: 28px;
    color: #666666;
  }

  .text-row {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 400;
    color: #666666;
    line-height: 33px;
  }
  .fontSize{
    font-size: 26px !important;
    color: #333333;
    margin-top: 24px;

  }
  .border{
    border-top: 1px solid #EEEEEE;
    margin-bottom: 24px;
    width:100%;
    margin-top:16px;
  }

}

.mt24 {
  margin-top: 24px;
}

.status {
  height: 48px;
  padding: 0 16px;
  margin-left: 24px;
  line-height: 48px;
  text-align: center;
  border-radius: 24px;
  float: right;
  font-size: 24px;
}

.grey {
  border: 1px solid #dddddd;
  color: #999;
}

.blue {
  border: 1px solid #1C9CF6;
  color: #1C9CF6;
}

</style>
