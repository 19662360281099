<!--
 * @Author: your name
 * @Date: 2021-03-13 10:23:16
 * @LastEditTime: 2024-12-25 15:01:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lsz-user-mp\src\views\expenseList\expenseList.vue
-->
<template>
  <div class="expenseList">
    <patientSelect @onConfirm="patientConfirm" />
    <van-tabs v-model="activeName" @click="onClick">
      <van-tab title="待支付" name="WAIT">
        <div class="unpaid">
          <div class="type-tip">
            <img src alt>
            缴费提示：药品一经发出，不得退换！
          </div>
          <ChargeList
            :type="type"
            :prescription-costs="prescriptionCosts"
            :register-costs="registerCosts"
            :jy-costs="jyCosts"
            :express-order-costs="expressOrderCosts"
          />
        </div>
      </van-tab>
      <van-tab title="历史记录" name="HISTOT">
        <div class="unpaid">
          <div class="title">
            日期选择
          </div>
          <div>
            <van-tabs type="card" color="#203D8B" @click="timeClick">
              <van-tab
                v-for="(item, index) in choosetime"
                :key="index"
                :title="item.name"
                :name="item.id"
              />
            </van-tabs>
          </div>
          <ChargeList
            :type="type"
            :prescription-costs="prescriptionCosts"
            :register-costs="registerCosts"
            :jy-costs="jyCosts"
            :express-order-costs="expressOrderCosts"
          />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import ChargeList from "../../components/chargelist/ChargeList"
import { userModel } from "../../api/user"
import { reduceDate, getDate } from "../../utils/index"
export default {
  components: {
    ChargeList
  },
  data() {
    return {
      patientIdCard: "",
      status: "",
      dateBegin: reduceDate(getDate(), 30),
      dateEnd: getDate(),
      activeName: "WAIT",
      prescriptionCosts: [],
      registerCosts: [],
      jyCosts: [],
      expressOrderCosts: [],
      type: true,
      tabList: [
        {
          name: "待支付",
          type: "WAIT",
          id: 1
        },
        {
          name: "历史记录",
          type: "HISTOT",
          id: 2
        }
      ],
      choosetime: [
        {
          name: "近一个月",
          id: 30
        },
        {
          name: "近三个月",
          id: 90
        },
        {
          name: "近一年",
          id: 365
        }
      ]
    }
  },
  methods: {
    async onClick(name) {
      if (name === "WAIT") {
        this.type = true
        await this.getCostList()
      } else {
        this.type = false
        await this.getHostryList()
      }
    },
    timeClick(name) {
      // console.dir(name,"qeq")
      this.dateBegin = reduceDate(getDate(), name)
      this.getHostryList()
    },
    async patientConfirm(value) {
      this.patientIdCard = value.zjh || ""
      this.dataList = []
      if (this.activeName === "WAIT") {
        await this.getCostList()
      } else {
        await this.getHostryList()
      }
    },
    async getCostList() {
      const { data } = await userModel.costInfo({
        zjhm: this.patientIdCard,
        status: this.status
      })
      this.prescriptionCosts = data.prescriptionCosts || []
      this.registerCosts = data.registerCosts || []
      this.jyCosts = data.jyCosts || []
      this.expressOrderCosts = data.expressOrder || []
    },
    async getHostryList() {
      userModel
        .historyList({
          dateBegin: this.dateBegin,
          dateEnd: this.dateEnd,
          zjhm: this.patientIdCard,
          status: this.status
        })
        .then((res) => {
          this.registerCosts = res.data.registerCosts
          this.prescriptionCosts = res.data.prescriptionCosts || []
          this.jyCosts = res.data.jyCosts || []
          this.expressOrderCosts = res.data.expressOrder || []
        })
      //this.prescriptionCosts=data.prescriptionCosts||[]
      //       console.dir(data,"registerCosts")
      // this.registerCosts=data.registerCosts||[]
      // console.dir(this.registerCosts,"registerCosts")
    }
  }
}
</script>
<style scoped lang='less'>
.expenseList {
  background: #f5f5f5;
  .unpaid {
    .type-tip {
      display: flex;
      align-items: center;
      color: #f16f26;
      font-size: 20px;
      padding: 32px 32px 0;

      .img {
        width: 22px;
        height: 22px;
        margin-right: 3px;
      }
    }
    .title {
      padding: 32px 32px 0;
      margin-bottom: 32px;
      font-size: 32px;
      font-weight: bold;
      color: #3d3d3d;
    }
  }
}
</style>
