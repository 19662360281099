<!--
 * @Author: your name
 * @Date: 2021-03-13 11:13:06
 * @LastEditTime: 2024-12-25 15:18:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lsz-user-mp\src\components\chargelist\ChargeList.vue
-->
<template>
  <div class="chargeList">
    <div class="unpaid-item">
      <div class="title">
        挂号费
      </div>
      <div class="registration-list">
        <div v-for="(v,index) in registerCosts" :key="index" :data-url="type?'/appointment/detail':'/appointment/success'" :data-id="type? v.yyghdid : v.id" class="registration-item" @click="unpiadgoPage">
          <div class="info">
            <div class="number">
              预约序号：{{ v.yyxh }}
            </div>
            <div class="department">
              {{ v.ksmc }}
            </div>
            <div>{{ v.hylxmc }}</div>
            <div class="time">
              {{ v.createTime }} {{ v.sxwbzmc }}
            </div>
          </div>
          <div class="price">
            <div v-if="v.sfbz == 2" class="tips status blue">
              已退费
            </div>
            <div class="price-text">
              <span class="_price">¥ {{ v.ghf }}</span>
            </div>
            <div v-if="!type">
              <van-button
                plain
                round
                type="primary"
                :loading="loading"
                @click="create(v)"
              >
                去开票
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div class="unpaid-item">
      <div class="title">
        药品处方费
      </div>
      <div class="registration-list">
        <div v-for="(item,indexs) in prescriptionCosts" :key="indexs" :data-url="type?'/pay':'/paid'" :data-id="item.id" class="prescription-item" @click="unpiadgoPage">
          <div class="info">
            <div class="number">
              处方编号：{{ item.cfh }}
            </div>
            <div class="diagnosis">
              医生诊断:
              <span class="tyep">{{ item.zdmc }}</span>
            </div>
            <div class="doctors">
              <div class="doctor-item">
                诊断医师：
                <span class="name">{{ item.kfysxm }}</span>
              </div>
              <div class="’doctor-item">
                审方医师：
                <span class="name">{{ item.ysxm }}</span>
              </div>
            </div>
          </div>
          <div class="price">
            <div v-if="item.sfbz == 2" class="tips status blue">
              已退费
            </div>
            <div class="price-text">
              <span class="_price">¥ {{ item.zj }}</span>
            </div>
            <div v-if="!type">
              <van-button
                plain
                round
                type="primary"
                :loading="loading"
                @click="createPrescriptionInvoice(item)"
              >
                去开票
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="unpaid-item">
      <div class="title">
        快递费
      </div>
      <div class="registration-list">
        <div v-for="(v,index) in expressOrderCosts" :key="index" :data-url="'/expressOrderPay'" :data-id="v.orderid" class="registration-item" @click="unpiadgoPage">
          <div class="info">
            <div class="number">
              订单号：{{ v.kdfddh }}
            </div>
            <div>处方号：{{ v.cfh }}</div>
            <div>收货人：{{ v.shr }}</div>
            <div>联系电话：{{ v.lxfs }}</div>
            <div>收货地址：{{ v.shdz }}</div> 
          </div>
          <div class="price">
            <div>
              <div class="tips status blue">
                {{ v.ddztmc }}
              </div>
              <div class="price-text">
                <span class="_price">¥ {{ v.kdfje }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="unpaid-item">
      <div class="title">
        检验费
      </div>
      <div class="registration-list">
        <div v-for="(item,indexs) in jyCosts" :key="indexs" :data-url="'/inspectPay'" :data-id="item.id" class="prescription-item" @click="goInspectPayPage">
          <div class="info">
            <div class="number">
              检验单号：{{ item.ddbm }}
            </div>
            <div class="diagnosis">
              医生诊断:
              <span class="tyep">{{ item.zdmc }}</span>
            </div>
            <div class="doctors">
              <div class="doctor-item">
                诊断医师：
                <span class="name">{{ item.ysxm }}</span>
              </div>
            </div>
            <div class="jy-price">
              <div>
                <span class="price-item">费用：</span>
                <span class="price-red">¥ {{ item.ddzj }}</span>
              </div>
            </div>
            <div class="doctors">
              <div class="doctor-item">
                创建时间：
                <span class="name">{{ item.createTime }}</span>
              </div>
            </div>
          </div>
          <div class="price">
            <div v-if="item.sfbz == 2" class="price-text blue">
              已退费
            </div>
            <div v-if="!type">
              <van-button
                plain
                round
                type="primary"
                :loading="loading"
                @click="createJyInvoice(item)"
              >
                去开票
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { invocieModel } from '../../api/invoice'

export default {
  props:{
    prescriptionCosts:{
      type:Array,
      default: () => ([])
    },
    registerCosts:{
      type:Array,
      default: () => ([])
    },
    jyCosts:{
      type:Array,
      default: () => ([])
    },
    expressOrderCosts:{
      type:Array,
      default: () => ([])
    },
    type:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  // 解决跳转到开票页面,发票信息展示不全的bug
  // vue-pdf只有第一次进入页面时才能正常展示pdf
  beforeDestroy() {
    if (this.reload) {
      window.location.reload()
    }
  },
  methods:{
    unpiadgoPage(e){
      if (e.target.nodeName !== 'BUTTON') {
        const url = e.currentTarget.dataset.url
        const id = e.currentTarget.dataset.id
        console.log(url, id,'url,id---------')
        this.$router.push({
          path:url,
          query:{id:id}
        })
      }
    },
    goInspectPayPage(e){
      if (e.target.nodeName !== 'BUTTON') {
        const url = e.currentTarget.dataset.url
        const id = e.currentTarget.dataset.id
        this.$router.push({
          path:url,
          query:{id:id}
        })
      }
    },
    async create(item) {
      try {
        this.loading = true
        if (item.sfbz == 1) {
          // 开正票
          await invocieModel.create(item.registerid)
        } else if (item.sfbz == 2) {
          // 开红票
          await invocieModel.refundInvoice(item.registerid)
        }
        this.gotoInvoice(item.registerid, item.sfbz)
      } finally {
        this.loading = false
      }
    },
    async createPrescriptionInvoice(item) {
      try {
        this.loading = true
        if (item.sfbz == 1) {
          // 开正票
          await invocieModel.createPrescriptionInvoice(item.cfddbm)
        } else if (item.sfbz == 2) {
          // 开红票
          await invocieModel.createPrescriptionRefundInvoice(item.cfddbm)
        }
        this.gotoInvoice(item.cfddbm, item.sfbz)
      } finally {
        this.loading = false
      }
    },
    async createJyInvoice(item) {
      try {
        this.loading = true
        if (item.sfbz == 1) {
          // 开正票
          await invocieModel.createJyInvoice(item.ddbm)
        } else if (item.sfbz == 2) {
          // 开红票
          await invocieModel.createJyRefundInvoice(item.ddbm)
        }
        this.gotoInvoice(item.ddbm, item.sfbz)
      } finally {
        this.loading = false
      }
    },
    gotoInvoice(orderNumber, type) {
      this.reload = true
      this.$router.push({
        path: '/invoice/' + orderNumber,
        query: {
          type
        }
      })
    }
  }
}
</script>
<style scoped lang='less'>
div {
  box-sizing: border-box;
}
.chargeList {
  padding: 32px 32px 0;
  background: #fff;
  margin-top:32px;
  .unpaid-item {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      margin-bottom: 32px;
      font-size: 32px;
      font-weight: bold;
      color: #3d3d3d;
    }
    .registration-list {
      margin: 0 -32px;
      border-top: 1px solid #eee;
      .registration-item,
      .prescription-item {
        position: relative;
        display: flex;
        padding: 30px 32px 24px;
        line-height: 33px;
        font-size: 24px;
        border-bottom: 1px solid #eee;
        .info {
          margin-left: 6px;

          .number {
            margin-bottom: 8px;
            line-height: 40px;
            font-size: 28px;
            color: #3d3d3d;
          }
        }
        .price {
          position: absolute;
          right: 32px;
          top: 30px;
          bottom: 24px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
          font-size: 32px;
          color: #dc3030;
          .status {
            text-align: right;
          }
          .price-text{
            margin-top: 12px;
            text-align: right;
          }
          .van-button--primary {
            height: 60px;
            line-height: 60px;
            color: #3A8AE5;
            border-color: #3A8AE5;
          }
        }
      }
      .registration-item {
        color: #999;
        .info {
          .department {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            color: #666;

            .doctor {
              margin-left: 24px;
            }

            .post {
              margin-left: 24px;
              font-size: 20px;
              color: #999;
            }
          }

          .time {
            margin-top: 8px;
            font-size: 20px;
          }
        }
      }
      .prescription-item {
        color: #666;

        .diagnosis {
          width: 70%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 8px;

          .type {
            color: #f16f26;
            width: 50%;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .doctors {
          display: flex;
          align-items: center;
          .doctor-item {
            margin-right: 72px;

            .name {
              color: #999;
            }
          }
        }
        .jy-price{
          display: flex;
          align-items: center;
          .price-item {
            margin-right: 72px;
            .price0-red {
              color: #dc3030;
            }
          }
        }
      }
    }
  }
}
</style>
