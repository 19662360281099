<!--已审核待支付-->
<template>
  <div>
    <div class="red-box">
      <p v-if="details.ddzt === '0'">
        请在3个工作日内完成支付，超时订单将取消
      </p>
    </div>
    <div class="page-box">
      <div class="card-box">
        <div class="title">
          {{ details.ddztmc }}
        </div>
        <div
          v-for="(item, index) in details.orderInfoGlList"
          :key="index"
          class="jy-item"
        >
          <div class="jy-item-title">
            {{ item.jyxmmc }}
            <div
              v-for="(secondItem, secondIndex) in item.jyInfoDetailList"
              :key="secondIndex"
              class="second-item"
            >
              <div>{{ secondItem.xmmc }}</div>
              <div>{{ secondItem.xmdj }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-box">
        <div class="cell">
          <div class="cell_title">
            订单总价
          </div>
          <div class="cell_value middle-color">
            ¥ {{ details.ddzj }}
          </div>
        </div>
        <div class="cell">
          <div class="cell_title">
            实付款
          </div>
          <div class="cell_value red-color">
            ¥ {{ details.ddzj }}
          </div>
        </div>
      </div>
    </div>
    <div class="footers">
      <div class="message">
        {{ text }}
      </div>
      <div class="pay">
        <van-button
          v-if="details.ddzt === '0'"
          type="info"
          round
          size="small"
          :loading="loading"
          class="footer-btn"
          @click="toPay(details)"
        >
          立即支付
        </van-button>
        <van-button
          v-if="details.ddzt === '1'"
          type="info"
          round
          size="small"
          :loading="loading"
          class="footer-btn"
          @click="refund(details)"
        >
          申请退费
        </van-button>
        <div v-if="details.ddzt === '0'" class="footer-text">
          合计：
          <span class="red-color">¥ {{ details.ddzj }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { appointModel } from "@/api/appoint"
import { CONSTANT } from "@/utils/constant"

export default {
  data() {
    return {
      checked: true,
      text: "",
      detailList: [],
      details: {},
      address: {
        dpmc: "",
        ddmc: "",
        dcmc: ""
      },
      orderId: -1,
      loading: false
    }
  },
  mounted() {
    this.orderId = this.$route.query.id
    this.queryCost()
  },
  methods: {
    queryCost() {
      appointModel.getJyDetail(this.orderId).then((res) => {
        this.detailList = res.data.details
        this.details = res.data
      })
    },
    toPay(details) {
      // 50 挂号 51 处方
      this.loading = true
      appointModel
        .checkstandCreate({ lb: 51, orderCode: details.ddbm })
        .then((res) => {
          if (res.code == CONSTANT.SUCCESS_CODE) {
            if (res.data === 'mock_success') {
              this.$router.replace("/")
            }else{
              location.href = res.data.webURL
            }
          }
        })
        .catch((error) => {
          this.$toast(error.msg)
        })
        .finally(() => {
          this.loading = false
        })
    },
     refund(details) {
      this.loading = true
      appointModel
        .refundJyApply({ddbm: details.ddbm })
        .then((res) => {
          if (res.code == CONSTANT.SUCCESS_CODE) {
            this.$toast("申请退费成功！")
          }
        })
        .catch((error) => {
          this.$toast(error.msg)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../../assets/styles/variable.less";

.red-box {
  width: 100%;
  height: 168px;
  background: #dc3030;

  p {
    color: #ffffff;
    margin: 0;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 88px;
  }
}

.page-box {
  padding: 0 32px 50px;
  box-sizing: border-box;
  position: relative;
  top: -82px;

  .flex-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .card-box {
    width: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 40px 32px;
    margin-bottom: 24px;
    box-sizing: border-box;
    z-index: 2;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-weight: bold;
      color: @dark-font-color;
    }

    .cell {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 16px 0;
      font-weight: 400;
      overflow: hidden;
      color: @dark-font-color;
      font-size: 28px;
      line-height: 40px;
    }

    .cell_title,
    .cell_value {
      flex: 1 1 auto;
    }

    .cell_value {
      overflow: hidden;
      text-align: right;
    }
    .jy-item {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      .jy-item-title {
        font-size: 26px;
        font-weight: bold;
        color: @dark-font-color;
        margin-top: 24px;
      }
      .second-item {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-left: 24px;
        font-size: 24px;
        margin-top: 24px;
      }
    }
  }

  .icon {
    position: relative;
    top: 8px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 98px;
  background: #ffffff;
  text-align: right;
  padding: 16px 32px;
  box-sizing: border-box;

  .footer-text {
    line-height: 68px;
    margin-right: 20px;
  }

  .footer-btn {
    width: 182px;
    font-size: 28px;
  }
}

.fl-right {
  float: right;
  margin-top: 6px;
}
.footers {
  position: fixed;
  bottom: 0;
  //display: flex;
  //flex-direction: row-reverse;
  width: 100%;
  height: 198px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 32px;
  box-sizing: border-box;

  .message {
    line-height: 1.7;
    color: #f83a1b;
  }

  .pay {
    display: flex;
    flex-direction: row-reverse;

    .footer-text {
      line-height: 68px;
      margin-right: 20px;
    }

    .footer-btn {
      width: 182px;
      font-size: 28px;
    }
  }
}
</style>
