/*
 * @Author: your name
 * @Date: 2021-03-09 14:29:23
 * @LastEditTime: 2024-12-25 14:48:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lsz-user-mp\src\api\user.js
 */
import { Http } from '@/utils/http'

export class UserModel extends Http {
  /**
   * 微信code换取wxtoken
   * @param {"appid": "","code": ""} params
   */
  wxCodeLogin(params) {
    return this.post('/user/wx_code_login', params)
  }
  //code换openid
  getWxTokenByCode(params) {
    return this.post('/user/getWxTokenByCode', params)
  }

  //验证码登录
  login(params) {
    return this.post('/user/login_by_captcha', params)
  }

  loginByPwd(params) {
    return this.post('/user/login_by_password', params)
  }

  //发送验证码
  getVerifyCode(phone) {
    return this.post('/sms/send_captcha/' + phone)
  }

  //刷新token
  refreshToken(params) {
    return this.post('/user/refresh-token', params)
  }

  //注册
  regist(params) {
    return this.post('/user/reg', params)
  }

  logout() {
    return this.post('/user/logout')
  }

  modifyPassword(params) {
    return this.post('/user/modify_pwd', params)
  }

  //我关注的医生列表
  doctorList(page, size) {
    return this.post('/user/mydoctor/list?page=' + page + '&pageSize=' + size)
  }

  //就诊人列表
  patientList() {
    return this.post('/user/patientinfo/patientAll')
  }

  patientAdd(params) {
    return this.post('/user/patientinfo/insert', params)
  }

  patientEdit(params) {
    return this.post('/user/patientinfo/edit', params)
  }

  //地址列表
  addressList() {
    return this.post('/user/addressinfo/list')
  }

  addressAdd(params) {
    return this.post('/user/addressinfo/insert', params)
  }

  addressEdit(params) {
    return this.post('/user/addressinfo/edit', params)
  }

  addressDel(id) {
    return this.post('/user/addressinfo/delete', { id }, { isFormData: true })
  }

  //正在进行的服务
  doingServeList() {
    return this.post('/user/myAppointment/hzlist')
  }

  //我的处方列表
  prescriptionList(params) {
    return this.post('/user/myPrescription/list', params, { isFormData: true })
  }

  //我的预约挂号
  appointmentList(params) {
    return this.post('/user/myAppointment/list', params, { isFormData: true })
  }

  //处方订单支付详情页信息
  preCostDetail(preOrderId) {
    return this.post('/user/cost/preCostDetail', { preOrderId }, { isFormData: true })
  }

  //处方订单支付详情
  preOrderDetail(preOrderId) {
    return this.post('/user/prescriptionOrder/pre-order-message', { preOrderId }, { isFormData: true })
  }

  //创建支付单
  wexinpayment(params) {
    return this.post('/wxpay/create_pay', params)
  }

  createPayOrder(params) {
    return this.post('/wxpay/create_pay_jsApi', params)
  }

  queryOrder(outTradeNo) {
    return this.post('/wxpay/orderQuery', { outTradeNo }, { isFormData: true })
  }

  // 处方单绑定配送地址
  bindAddress(preOrderId, addressId, psfs = 1) {
    return this.post('/user/prescriptionOrder/preorderaddress', {
      preOrderId,
      addressId,
      psfs
    }, { isFormData: true })
  }

  //查询待支付挂号，处方费用信息
  costInfo(params) {
    return this.post('/user/cost/selectNotCost', params, { isFormData: true })
  }

  //查询历史列表
  historyList(params) {
    return this.post('/user/cost/selectHistoryCost', params, { isFormData: true })
  }

  updatePwd(params) {
    return this.post('/user/modify_pwd', params)
  }

  forgetPwd(params) {
    return this.post('/user/forget_pwd', params)
  }

  //查询医生环信账号信息
  doctorByuser(params) {
    return this.post(`/user/myAppointment/doctorbyuser`, params, { isFormData: true })
  }

  //候诊签到
  waitSign(params) {
    return this.post(`/user/appointment/signInWaiting`, params, { isFormData: true })
  }

  //排队数量
  already(params) {
    return this.post(`/user/appointment/alreadySignIn`, params, { isFormData: true })
  }

  //写评价/user/evaluate/write
  write(params) {
    return this.post(`/user/evaluate/write`, params, { isFormData: true })
  }

  //查询评价信息
  myEvaluate(params) {
    return this.post(`/user/myAppointment/evaluate`, params, { isFormData: true })
  }

  //查询挂号支付详情
  registrationdetail(params) {
    return this.post(`/user/cost/registerCostDetail`, params, { isFormData: true })
  }

  // 我的影像 飞图影像列表
  getFTImage(params) {
    return this.post('/user/ftimage/list', params)
  }

  // 查询配送费订单详情
  expressOrderDetail(params) {
    return this.post('/user/expressOrder/getDetail', params, { isFormData: true })
  }
  
}

export const userModel = new UserModel()
