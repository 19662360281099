<!--已审核待支付-->
<template>
  <div>
    <div class="red-box">
      <p>请注意，处方订单需快递费支付完成后才能配送！</p>
    </div>
    <div class="page-box">
      <div class="card-box">
        <div class="tit">
          快递费订单状态：{{ details.ddztmc }}
        </div>
        <div class="tit">
          快递费订单号：{{ details.kdfddh }}
        </div>
        <div class="cell">
          <div class="cell_title">
            收货人
          </div>
          <div class="cell_value middle-color">
            {{ details.shr }} 
          </div>
        </div>
        <div class="cell">
          <div class="cell_title">
            联系方式
          </div>
          <div class="cell_value middle-color">
            {{ details.lxfs }} 
          </div>
        </div>
        <div class="cell">
          <div class="cell_title">
            收货地址
          </div>
          <div class="cell_value middle-color">
            {{ details.shdz }} 
          </div>
        </div>
        <div class="cell">
          <div class="cell_title">
            快递费金额（元）
          </div>
          <div class="cell_value red-color">
            ¥ {{ details.kdfje }}
          </div>
        </div>
      </div>
      <div v-if="details.ddzt ==='0'" class="card-box">
        <div class="title">
          选择支付方式
        </div>
        <div class="cell">
          <div class="cell_title">
            <van-icon name="wechat-pay" class="icon green-color size32" />
            微信支付
          </div>
          <div class="cell_value">
            <van-checkbox
              v-model="checked"
              checked-color="#09BB07"
              icon-size="16px"
              class="fl-right"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="details.ddzt ==='0'">
      <Pay
        :price="details.kdfje"
        :text="text"
        :order-id="details.id"
        :order-type-key="3"
        @success="onPaySuccess"
      />
    </div>
  </div>
</template>
<script>
import { userModel } from '@/api/user'
import Pay from '@/components/pay/pay'

export default {
  components: {
    Pay
  },
  data() {
    return {
      details: {},
      orderId: -1,
      checked: true,
      text: '挂号费用支付后，在当日问诊时段结束前，若您未签到，可以进行退款。超过问诊时段或已进行签到，则不能退款。'
    }
  },
  mounted() {
    this.orderId = +this.$route.query.id
    this.queryCost()
  },
  methods: {
    queryCost() {
      userModel.expressOrderDetail({ orderid: this.orderId }).then(res => {
        this.details = res.data || {}
      })
    },
    onPaySuccess() {
      this.$router.replace('/')
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";

.red-box {
  width: 100%;
  height: 168px;
  background: #dc3030;

  p {
    color: #ffffff;
    margin: 0;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 88px;
  }
}

.page-box {
  padding: 0 32px 50px;
  box-sizing: border-box;
  position: relative;
  top: -80px;

  .card-box {
    width: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 40px 32px;
    margin-bottom: 24px;
    box-sizing: border-box;
    z-index: 2;

    .tit {
      width: 100%;
      color: @dark-font-color;
      padding-bottom: 20px;
      text-align: center;
      font-size: 28px;
      font-weight: bold;
    }

    .title {
      font-size: 32px;
      font-weight: bold;
      color: @dark-font-color;
    }

    .radio-group {
      display: flex;
      justify-content: space-between;

      .radio-btn {
        width: 176px;
        height: 48px;
        line-height: 48px;
        color: #999999;
        text-align: center;
        border-radius: 24px;
        border: 1px solid #999999;

        &.active {
          color: @theme-color;
          border: 1px solid @theme-color;
        }
      }
    }

    .cell {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 16px 0;
      font-weight: 400;
      overflow: hidden;
      color: @dark-font-color;
      font-size: 28px;
      line-height: 40px;
    }

    .cell_title{
        white-space: nowrap;
        margin-right: 24px;
    }

    .cell_value {
      flex: 1 ;
    }

    .cell_value {
      overflow: hidden;
      text-align: right;
    }
  }

  .icon {
    position: relative;
    top: 8px;
  }
}

.fl-right {
  float: right;
  margin-top: 6px;
}
</style>
